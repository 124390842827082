// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import auth from "./auth";
import workspaces from "./workspaces";
import users from "./users";
import companies from "./companies";
import plans from "./plans";
import timezones from "./timezones";
import bookings from "./bookings";
import feedbacks from "./feedbacks";
import notifications from "./notifications";
import settings from "./settings";

const rootReducer = {
  navbar,
  layout,
  auth,
  workspaces,
  users,
  companies,
  plans,
  timezones,
  bookings,
  feedbacks,
  notifications,
  settings
};

export default rootReducer;

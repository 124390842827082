// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get users API
export const getCompanies = createAsyncThunk(
  "companies/getCompanies",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/companies`,
        payload
      );
      return {
        data: {
          companies: response.data.companies,
          total: response.data.total,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          companies: [],
          total: 0,
        },
      };
    }
  }
);

export const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    //Companies table related attributes
    companies: [],
    companiesLoading: true,
    totalCompanies: 0,
    rowsPerPage: 50,
    currentPage: 1,
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.companies = action.payload.data.companies;
      state.totalCompanies = action.payload.data.total;
      state.companiesLoading = false;
    });
  },
});

export const { storeCurrentPage, storeRowsPerPage } = companiesSlice.actions;

export default companiesSlice.reducer;

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get users API
export const getUsers = createAsyncThunk("users/getUsers", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/admin/users`,
      payload
    );
    return {
      data: {
        users: response.data.users,
        total: response.data.total,
      },
    };
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    return {
      data: {
        users: [],
        total: 0,
      },
    };
  }
});

// prettier-ignore
//const user = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
export const usersSlice = createSlice({
  name: "users",
  initialState: {
    //Workspace table related attributes
    
    //Workspace users table related attributes
    users:[],
    usersLoading:true,
    totalUsers: 0,
    rowsPerPageUser:50,
    currentPageUser:1,

  },
  reducers: {
    
    storeCurrentPageUser: (state, action) => {
      state.currentPageUser = action.payload.currentPage
    },
    storeRowsPerPageUser: (state, action) => {
      state.rowsPerPageUser = action.payload.rowsPerPage
    },
    // storeCurrentWorkspace: (state, action) => {
    //   state.currentWorkspace = action.payload.workspace
    // },
    // getWorkspace: (state, action) => {
    //   state.query = action.payload
    // }
  },
  extraReducers: (builder) => {
    builder
      
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.data.users;
        state.totalUsers = action.payload.data.total;
        state.usersLoading = false;
      });
  },
});

export const {
  storeCurrentPageUser,
  storeRowsPerPageUser,
  // storeCurrentWorkspace,
} = usersSlice.actions;

export default usersSlice.reducer;

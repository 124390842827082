// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get timezones API
export const getTimezones = createAsyncThunk("timezones/getTimezones", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/admin/timezones`,
      payload
    );
    return {
      data: {
        timezones: response.data.timezones,
        total: response.data.total,
      },
    };
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    return {
      data: {
        timezones: [],
        total: 0,
      },
    };
  }
});

// Perform create timezone API
export const createTimezone = createAsyncThunk(
  "timezones/createTimezone",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/timezones/create`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          timezone: response.data.timezone,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          timezone: false,
        },
      };
    }
  }
);

// Perform update timezone API
export const updateTimezone = createAsyncThunk(
  "timezones/updateTimezone",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/timezone/${payload.id}`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          timezone: response.data.timezone,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          timezone: false,
        },
      };
    }
  }
);

export const timezonesSlice = createSlice({
  name: "timezones",
  initialState: {
    // Timezones table related attributes
    timezones: [],
    timezonesLoading: true,
    totalTimezones: 0,
    rowsPerPage: 50,
    currentPage: 1,
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    storeCurrentPagePrice: (state, action) => {
      state.currentPagePrice = action.payload.currentPage;
    },
    storeRowsPerPagePrice: (state, action) => {
      state.rowsPerPagePrice = action.payload.rowsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimezones.fulfilled, (state, action) => {
        state.timezones        = action.payload.data.timezones;
        state.totalTimezones   = action.payload.data.total;
        state.timezonesLoading = false;
      })
      .addCase(createTimezone.fulfilled, (state, action) => {
        console.log(state, action);
      })
      .addCase(updateTimezone.fulfilled, (state, action) => {
        console.log(state, action);
      })
    },
});

export const {
  storeCurrentPage,
  storeRowsPerPage,
  storeCurrentPagePrice,
  storeRowsPerPagePrice,
} = timezonesSlice.actions;

export default timezonesSlice.reducer;

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get feedbacks API
export const getFeedbacks = createAsyncThunk("feedbacks/getFeedbacks", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/admin/feedbacks`,
      payload
    );
    return {
      data: {
        feedbacks: response.data.feedbacks,
        total: response.data.total,
      },
    };
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    return {
      data: {
        feedbacks: [],
        total: 0,
      },
    };
  }
});

export const feedbacksSlice = createSlice({
  name: "feedbacks",
  initialState: {
    // Feedbacks table related attributes
    feedbacks: [],
    feedbacksLoading: true,
    totalTimezones: 0,
    rowsPerPage: 50,
    currentPage: 1,
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    storeCurrentPagePrice: (state, action) => {
      state.currentPagePrice = action.payload.currentPage;
    },
    storeRowsPerPagePrice: (state, action) => {
      state.rowsPerPagePrice = action.payload.rowsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbacks.fulfilled, (state, action) => {
        state.feedbacks        = action.payload.data.feedbacks;
        state.totalFeedbacks   = action.payload.data.total;
        state.feedbacksLoading = false;
      })
    },
});

export const {
  storeCurrentPage,
  storeRowsPerPage,
  storeCurrentPagePrice,
  storeRowsPerPagePrice,
} = feedbacksSlice.actions;

export default feedbacksSlice.reducer;

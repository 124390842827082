// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get bookings API
export const getBookings = createAsyncThunk("bookings/getBookings", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/admin/bookings`,
      payload
    );
    return {
      data: {
        bookings: response.data.bookings,
        total: response.data.total,
      },
    };
  } catch (e) {
    toast.error(e.response.data.message);
    return {
      data: {
        bookings: [],
        total: 0,
      },
    };
  }
});

// Perform create booking API
export const createBooking = createAsyncThunk(
  "bookings/createBooking",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/bookings/create`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          booking: response.data.booking,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          booking: false,
        },
      };
    }
  }
);

// Perform update timezone API
export const updateBooking = createAsyncThunk(
  "bookings/updateBooking",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/booking/${payload.id}`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          booking: response.data.booking,
        },
      };
    } catch (e) {
      toast.error(e.response.data.message);
      return {
        data: {
          booking: false,
        },
      };
    }
  }
);

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState: {
    // Bookings table related attributes
    bookings: [],
    bookingsLoading: true,
    totalBookings: 0,
    rowsPerPage: 50,
    currentPage: 1,
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    storeCurrentPagePrice: (state, action) => {
      state.currentPagePrice = action.payload.currentPage;
    },
    storeRowsPerPagePrice: (state, action) => {
      state.rowsPerPagePrice = action.payload.rowsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookings.fulfilled, (state, action) => {
        state.bookings        = action.payload.data.bookings;
        state.totalBookings   = action.payload.data.total;
        state.bookingsLoading = false;
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        console.log(state, action);
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        console.log(state, action);
      })
    },
});

export const {
  storeCurrentPage,
  storeRowsPerPage,
  storeCurrentPagePrice,
  storeRowsPerPagePrice,
} = bookingsSlice.actions;

export default bookingsSlice.reducer;

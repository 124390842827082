// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get notifications API
export const getNotifications = createAsyncThunk("notifications/getNotifications", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/admin/notifications`,
      payload
    );
    return {
      data: {
        notifications: response.data.notifications,
        total: response.data.total,
      },
    };
  } catch (e) {
    toast.error(e.response.data.message);
    return {
      data: {
        notifications: [],
        total: 0,
      },
    };
  }
});

// Perform create notification API
export const createNotification = createAsyncThunk(
  "notifications/createNotification",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/notifications/create`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          notifications: [],
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          notifications: [],
        },
      };
    }
  }
);

// Perform update notification API
export const updateNotification = createAsyncThunk(
  "notifications/updateNotification",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/notification/${payload.id}`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          notification: response.data.notification,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          notification: false,
        },
      };
    }
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    // Notifications table related attributes
    notifications: [],
    notificationsLoading: true,
    totalNotifications: 0,
    rowsPerPage: 50,
    currentPage: 1,
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    storeCurrentPagePrice: (state, action) => {
      state.currentPagePrice = action.payload.currentPage;
    },
    storeRowsPerPagePrice: (state, action) => {
      state.rowsPerPagePrice = action.payload.rowsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications        = action.payload.data.notifications;
        state.totalNotifications   = action.payload.data.total;
        state.notificationsLoading = false;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        console.log(state, action);
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        console.log(state, action);
      })
    },
});

export const {
  storeCurrentPage,
  storeRowsPerPage,
  storeCurrentPagePrice,
  storeRowsPerPagePrice,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get settings API
export const getSettings = createAsyncThunk("settings/getSettings", async (payload) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/api/admin/settings`,
      payload
    );
    return {
      data: {
        settings: response.data.settings,
      },
    };
  } catch (e) {
    toast.error(e.response.data.message);
    return {
      data: {
        settings: [],
      },
    };
  }
});

// Perform update setting API
export const updateSetting = createAsyncThunk(
  "settings/updateSetting",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/setting/update`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          setting: response.data.setting,
        },
      };
    } catch (e) {
      toast.error(e.response.data.message);
      return {
        data: {
          setting: false,
        },
      };
    }
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    settings: [],
    settingsLoading: true
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.fulfilled, (state, action) => {
        state.settings        = action.payload.data.settings;
        state.settingsLoading = false;
      })
      .addCase(updateSetting.fulfilled, (state, action) => {
        console.log(state, action);
      })
    },
});

export default settingsSlice.reducer;

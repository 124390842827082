// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import toast from "react-hot-toast";

axios.defaults.withCredentials = true;

// Perform get plans API
export const getPlans = createAsyncThunk("plans/getPlans", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/api/admin/plans`,
      payload
    );
    return {
      data: {
        plans: response.data.plans,
        total: response.data.total,
      },
    };
  } catch (e) {
    console.log(e);
    toast.error(e.response.data.message);
    return {
      data: {
        plans: [],
        total: 0,
      },
    };
  }
});

// Perform create plan API
export const createPlan = createAsyncThunk(
  "plans/createPlan",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/plan`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          plan: response.data.plan,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          plan: false,
        },
      };
    }
  }
);

// Perform create plan API
export const updatePlan = createAsyncThunk(
  "plans/updatePlan",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/plan/${payload.id}`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          plan: response.data.plan,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          plan: false,
        },
      };
    }
  }
);

// Perform get prices of plan API
export const getPrices = createAsyncThunk(
  "plans/getPrices",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/plan-prices/${payload.id}`,
        payload
      );
      return {
        data: {
          prices: response.data.prices,
          total: response.data.total,
          isFreePlan: response.data.isFreePlan,
        },
      };
    } catch (e) {
      return {
        data: {
          prices: [],
          total: 0,
          isFreePlan: false,
        },
      };
    }
  }
);

// Perform create price of plan API
export const createPrice = createAsyncThunk(
  "plans/createPrice",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/create-plan-price/${payload.planId}`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          price: response.data.price,
        },
      };
    } catch (e) {
      toast.error(e.response.data.message);
      return {
        data: {
          price: null,
        },
      };
    }
  }
);

// Perform update price status API
export const updatePrice = createAsyncThunk(
  "plans/updatePrice",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/plan-price/${payload.id}`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          price: response.data.price,
        },
      };
    } catch (e) {
      console.log(e);
      toast.error(e.response.data.message);
      return {
        data: {
          price: false,
        },
      };
    }
  }
);

// Perform get features of plan API
export const getFeatures = createAsyncThunk(
  "plans/getFeatures",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/plan-features/${payload.planId}`
      );
      return {
        data: {
          features: response.data.features,
        },
      };
    } catch (e) {
      return {
        data: {
          features: [],
        },
      };
    }
  }
);

// Perform create feature of plan API
export const createPlanFeature = createAsyncThunk(
  "plans/createPlanFeature",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/create-plan-feature/${payload.planId}`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: {
          feature: response.data.feature,
        },
      };
    } catch (e) {
      return {
        data: {
          feature: false,
        },
      };
    }
  }
);

// Perform delete feature of plan API
export const deletePlanFeature = createAsyncThunk(
  "plans/deletePlanFeature",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/plan-feature/${payload.id}`
      );
      toast.success(response.data.message);
      return {
        data: true,
      };
    } catch (e) {
      return {
        data: false,
      };
    }
  }
);

// Perform delete feature of plan API
export const orderPlanFeatures = createAsyncThunk(
  "plans/orderPlanFeatures",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/order-plan-features/${payload.id}`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: true,
      };
    } catch (e) {
      return {
        data: false,
      };
    }
  }
);

// Perform order product of plan API
export const orderProducts = createAsyncThunk(
  "plans/orderProducts",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/order-products`,
        payload
      );
      toast.success(response.data.message);
      return {
        data: true,
      };
    } catch (e) {
      return {
        data: false,
      };
    }
  }
);

// Perform get active plans with feature of plan and prices
export const getActivePlans = createAsyncThunk(
  "plans/getActivePlans",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/api/admin/get-active-plans`
      );
      // toast.success(response.data.message);
      return {
        data: response.data.plans,
      };
    } catch (e) {
      return {
        data: [],
      };
    }
  }
);

export const plansSlice = createSlice({
  name: "plans",
  initialState: {
    //Plans table related attributes
    plans: [],
    plansLoading: true,
    totalPlans: 0,
    rowsPerPage: 50,
    currentPage: 1,

    //Price table related attributes
    prices: [],
    isFreePlan: false,
    totalPrices: 0,
    rowsPerPagePrice: 50,
    currentPagePrice: 1,

    activePlans: [],
  },
  reducers: {
    storeCurrentPage: (state, action) => {
      state.currentPage = action.payload.currentPage;
    },
    storeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload.rowsPerPage;
    },
    storeCurrentPagePrice: (state, action) => {
      state.currentPagePrice = action.payload.currentPage;
    },
    storeRowsPerPagePrice: (state, action) => {
      state.rowsPerPagePrice = action.payload.rowsPerPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlans.fulfilled, (state, action) => {
        state.plans = action.payload.data.plans;
        state.totalPlans = action.payload.data.total;
        state.plansLoading = false;
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        console.log(state, action);
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        console.log(state, action);
      })
      .addCase(getActivePlans.fulfilled, (state, action) => {
        state.activePlans = action.payload.data;
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        state.prices = action.payload.data.prices;
        state.totalPrices = action.payload.data.total;
        state.isFreePlan = action.payload.data.isFreePlan;
      });
  },
});

export const {
  storeCurrentPage,
  storeRowsPerPage,
  storeCurrentPagePrice,
  storeRowsPerPagePrice,
} = plansSlice.actions;

export default plansSlice.reducer;
